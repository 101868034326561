// @import url('https://fonts.googleapis.com/css?family=DM+Sans:400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;600;700&display=swap');
@import "variables.scss";


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $Light-Gray;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  outline: none;
  // font-family: 'DM Sans', sans-serif;
  font-family: 'Overpass', sans-serif;
}