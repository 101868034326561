@import "../../variables.scss";

.ManageDistributionStyles {
  .layout_middel_content {
    padding:16px 24px;
    height: calc(100vh - 134px);
    overflow: hidden;
    overflow-y: auto;
  }
}

