@import "../../variables.scss";

.ImportCatalogStyles {    

  .ImportCatalog_container {
    .ImportCatalog__title {
      font-size: 20px;
      line-height: 22px;
      color: $Dark-Blue;
      text-transform: capitalize;
      padding: 0 0 20px;
    }
    .ImportCatalog_whitebox {
      max-width: 60%;
      margin: 0 0 24px;
      @media #{$IpadP} {
        max-width: 100%;
       }
    }
  }  
}
