@import "../../variables.scss";

.ExportSampleOrderStyles {    

  .ExportSampleOrder_container {
    .ExportSampleOrder__title {
      font-size: 20px;
      line-height: 22px;
      color: $Dark-Blue;
      text-transform: capitalize;
      padding: 0 0 20px;
    }
    .ExportSampleOrder_whitebox {
      max-width: 60%;
      @media #{$IpadP} {
        max-width: 100%;
       }
    }
  }
   
}