@import "../../variables.scss";

.UserRoleTableStyles {
  .ant-table-content {
    @media #{$Small-Laptop} {
      overflow-x: auto;
      max-width: 1200px;
    }
  }

  .Table_block_main {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td, 
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      padding: 16px 8px;
      text-align: center;
    }

    .ant-table-tbody > tr > td, 
    .ant-table tfoot > tr > td {
      text-align: center;
      .username {
        font-weight: 600;
        color: $Dark-Grey-53;
      }
    }

    .ant-table-thead > tr > th:first-child,
    .ant-table tfoot > tr > th:first-child {
      text-align: left;
    }

    .ant-table-tbody > tr > td:first-child {
      text-align: left;
    }
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: $Light-Gray-fa;
  }
}