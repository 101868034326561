@import '../../variables.scss';

.CatalogManagementStyles {
  .layout_middel_content {
    padding: 16px 24px 0;
    height: calc(100vh - 134px);
    overflow: hidden;
    overflow-y: auto;
  }
}
