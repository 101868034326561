@import '../../variables.scss';

.EditProfileStyles {
  .layout_middel_content {
    padding: 16px 24px;
    height: calc(100vh - 134px);
    overflow: hidden;
    overflow-y: auto;
  }

  .EditProfile_container {
    .EditProfile__title {
      font-size: 20px;
      line-height: 22px;
      color: $Dark-Blue;
      text-transform: capitalize;
      padding: 0 0 20px;
    }
    .EditProfile_whitebox {
      max-width: 60%;
      @media #{$IpadP} {
        max-width: 100%;
      }
    }
  }
}
.loader {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader1 {
  position: fixed;
  left: 40%;
  top: 32%;
  z-index: 5;
}
