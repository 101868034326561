@import './variables.scss';

.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-layout-sider {
  background: $Theme-Blue-36;
  border-right: none;
  @media #{$PhoneL} {
    display: none;
    &.sider-open {
      display: block;
    }
  }
  .ant-menu {
    background: $Theme-Blue-36;
    border-right: 1px solid $Theme-Blue-36;
    li {
      font-size: 14px;
      color: $White;
      background: none;
      line-height: 45px;
      height: 45px;
      font-weight: 600;
      margin-top: 5px;
      margin-bottom: 5px;
      // padding-left: 16px;
      &.ant-menu-item-selected {
        color: $White;
        background: $Theme-Blue-36;
      }
      &:hover {
        color: $White;
      }
      &:after {
        border-color: $White;
        left: 0;
        right: auto;
      }
      &.ant-menu-item {
        .anticon {
          font-size: 16px;
        }
      }
      a {
        overflow: hidden;
        // text-overflow: ellipsis;
      }
    }
  }
  .ant-layout-sider-trigger {
    background: $Theme-Blue-36;
  }
}

.Sidebar_Header_Logo {
  width: 100%;
  height: auto;
  background: $White;
  margin: 0 auto;
  text-align: center;
  padding: 7px 0 8px;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  img {
    width: 100%;
    max-width: 170px;
  }
}

.site-layout {
  background: $Light-Gray-f9;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.ant-layout-content {
  background: none;
  padding: 0;
}

.ant-layout-header {
  background: $White;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $Border-Grey;
  padding: 0 25px 0 15px;
  height: 63px;
  .Header_left_column {
    display: flex;
    align-items: center;
    .anticon {
      &.trigger {
        font-size: 18px;
        color: $Dark-Grey-53;
        margin: 14px 0;
        transition: all 0.3s;
        padding: 10px;
        border-radius: 4px;
        &:hover {
          background: rgba(63, 135, 245, 0.15);
          color: $Theme-Blue-36;
          .anticon-delete {
            color: $Theme-Blue-36;
          }
        }
      }
    }
    .Search_col {
      width: 220px;
      text-align: left;
      border: none;
      background: none;
      box-shadow: none;
      padding: 3px 15px;
      display: flex;
      align-items: center;
      .anticon {
        font-size: 18px;
        color: $Dark-Grey-53;
        &:hover {
          background: none;
        }
      }
      input {
        border: none;
        background: none;
        box-shadow: none;
        color: $Dark-Grey-53;
        padding: 0 10px;
        font-size: 18px;
      }
    }
  }
  .Header_right_column {
    display: flex;
    align-items: center;
    .Noti_icon {
      padding-right: 10px;
      border-right: 1px solid $Border-Grey;
      margin-right: 10px;
      .anticon {
        font-size: 20px;
        color: $Dark-Grey-53;
        margin: 14px 0;
        transition: all 0.3s;
        padding: 10px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background: rgba(63, 135, 245, 0.15);
          color: $Theme-Blue-36;
          .anticon-delete {
            color: $Theme-Blue-36;
          }
        }
      }
    }

    .Header_user_avatar {
      .ant-dropdown-link {
        display: inline-block;
      }
      .Header_username {
        color: $Dark-Grey-53;
        padding-left: 5px;
        font-weight: 600;
        text-transform: capitalize;
      }
      .anticon {
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
      }
    }
  }
}

.notification__dropdown_main {
  padding: 0;
  .noti_list_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid $Border-Grey;
    &:last-child {
      border: none;
    }
    &:hover {
      background-color: $Light-Gray-fa;
    }
    .noti_list_row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .noti_list_title_left {
        .anticon {
          font-size: 16px;
          margin-right: 10px;
        }
        span {
          font-size: 16px;
          line-height: 18px;
          color: $Dark-Grey;
          font-weight: 600;
        }
      }
      .noti_viewall {
        .view_all_btn {
          color: $Theme-Blue-36;
          font-size: 13px;
        }
      }
      .ant-avatar {
        height: 40px;
        width: 40px;
      }
      .noti_info {
        flex-direction: column;
        padding-left: 12px;
        .noti_msg {
          padding: 0 0 5px;
          display: block;
          color: $Dark-Grey;
        }
        .noti_date {
          color: $Dark-Grey-72;
          font-size: 13px;
        }
      }
    }
  }
}

.profile_dropdown_main {
  padding: 0;
  border-radius: 4px;
  padding-bottom: 10px;
  .profile_list_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 20px;
    &:first-child {
      border-bottom: 1px solid $Border-Grey;
      padding: 18px 20px;
      margin-bottom: 10px;
    }
    &:hover {
      background-color: $Light-Gray-fa;
    }
    .profile_list_row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .profile_user_avatar {
        .ant-avatar {
          height: 48px;
          width: 48px;
          background: $Border-Grey;
        }
      }
      .profile_user_details {
        flex-direction: column;
        padding-left: 10px;
        .profile_username {
          text-transform: capitalize;
          padding: 0 0 6px;
          font-size: 16px;
          line-height: 18px;
          color: $Dark-Grey;
        }
        .profile_user_position {
          color: $Dark-Grey-72;
          opacity: 0.7;
          font-size: 15px;
        }
      }
    }
    .anticon {
      font-size: 16px;
      margin-right: 10px;
      opacity: 0.4;
    }
    span {
      color: $Dark-Grey-36;
      font-size: 15px;
    }
  }
}

.ant-breadcrumb {
  margin: 0 0 16px 0;
  border-bottom: 1px solid $Border-Grey;
  padding: 0 0 8px;
  span {
    color: $Dark-Grey-72;
    font-weight: 600;
    &:last-child {
      opacity: 0.7;
    }
  }
}

.layout_content_box {
  border: 1px solid $Border-Grey;
  background: $White;
  padding: 16px;
}

.Table_top_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 16px;
  @media #{$PhoneL} {
    flex-wrap: wrap;
  }
  .Table_heading {
    font-size: 20px;
    line-height: 22px;
    color: $Dark-Blue;
    text-transform: capitalize;
    font-weight: 600;
    @media #{$IpadP} {
      font-size: 22px;
      line-height: 24px;
    }
    @media #{$PhoneL} {
      font-size: 20px;
      line-height: 22px;
      margin: 12px 0 0;
      white-space: nowrap;
    }
  }
  .Table_action_btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1 auto;
    padding-left: 16px;
    button {
      margin-left: 10px;
      border-radius: 4px;
      box-shadow: none;
      font-weight: 600;
      @media #{$PhoneL} {
        margin: 12px 6px 0 0;
      }
      &:hover {
        border-color: $Theme-Blue-36;
        color: $Theme-Blue-36;
      }
    }
    .ant-btn-primary {
      background: $Theme-Blue-36;
      border-color: $Theme-Blue-36;
      color: $White;
      &:hover {
        color: $White;
      }
    }
  }
}

.Table_block_main {
  .ant-table {
    background: transparent;
    mask-image: linear-gradient(
      to top,
      transparent 0%,
      rgba(255, 255, 255, 1) 3%,
      rgba(255, 255, 255, 1) 100%,
      transparent 100%
    );
    mask-size: cover;
    -webkit-mask-image: linear-gradient(
      to top,
      transparent 0%,
      rgba(255, 255, 255, 1) 3%,
      rgba(255, 255, 255, 1) 100%,
      transparent 100%
    );
    -webkit-mask-size: cover;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > th,
  .ant-table tfoot > tr > td {
    padding: 10px 8px;
    white-space: nowrap;
    border: none;
    border-right: none !important;
  }

  .ant-table-thead > tr > th,
  .ant-table tfoot > tr > th {
    background: $White;
    border-bottom: 1px solid $Border-Grey;
    text-transform: capitalize;
    padding: 0 8px 12px 8px;
    color: $Dark-Grey-91;
    font-weight: 600;
  }

  .ant-table-thead > tr > th.filtered_text{
    color: #ff4d4f;
  }

  .ant-table-tbody > tr > td,
  .ant-table tfoot > tr > td {
    border-bottom: 1px solid $Border-Grey;
    font-size: 14px;
    color: $Dark-Grey-53;
    transition: all 0.3s, height 0s;
  }

  .ant-table-tbody > tr {
    &.ant-table-row:hover > td {
      background: #f9f9f9;
    }
  }

  .ant-table-tbody > tr {
    &.ant-table-row-selected > td {
      background: $Light-Gray-fa;
    }
  }

  .ant-table-cell-fix-left,
  .ant-table-cell-fix-right {
    right: 0 !important;
    top: auto !important;
  }

  .ant-table-ping-right .ant-table-cell-fix-right-first::after,
  .ant-table-ping-right .ant-table-cell-fix-right-last::after {
    box-shadow: none;
    -webkit-box-shadow: none;
    border: none;
  }

  .ant-table-ping-left:not(.ant-table-has-fix-left)
    .ant-table-container::before {
    box-shadow: none;
    -webkit-box-shadow: none;
    border: none;
  }

  .ant-table-ping-left .ant-table-cell-fix-left-first::after,
  .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    box-shadow: none;
    -webkit-box-shadow: none;
    border: none;
  }

  .ant-table.ant-table-bordered .ant-table-cell-fix-right-first::after {
    border: none;
  }

  .ant-table-content {
    @media #{$IpadP} {
      overflow-x: auto;
      max-width: 1024px;
    }
  }

  .ant-table-bordered {
    .ant-table-container {
      border: none;
    }
  }

  .editable-cell {
    position: relative;
  }

  .editable-cell-value-wrap {
    padding: 8px 12px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
    transition: all 0.3s;
    min-width: 115px;
  }

  .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid $Border-Grey;
    border-radius: 4px;
    padding: 8px 12px;
    background: $White;
  }

  .editable-row {
    .ant-form-item {
      input {
        border: 1px solid $Border-Grey;
        border-radius: 4px;
        padding: 8px 12px;
        background: $White;
        transition: all 0.3s;
        color: $Dark-Grey-72;
        min-width: 115px;
      }
    }
  }

  [data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
    border: 1px solid #434343;
  }

  .ant-table-cell {
    .ant-select-selector {
      background: $White;
      color: rgba(114, 132, 154, 0.5);
      border: 1px solid $Border-Grey;
      border-radius: 4px;
      height: 40px;
      padding: 4px 11px;
    }
    &:first-child {
      font-weight: 600;
      .ant-form-item {
        input {
          font-weight: 600;
        }
      }
    }
    .ant-select-open {
      .ant-select-selector {
        .ant-select-selection-item {
          opacity: 1;
        }
      }
    }
  }

  .ico_delete,
  .ico_save,
  .ico_edit {
    height: 32px;
    width: 32px;
    display: inline-block;
    border-radius: 50%;
    background: transparent;
    transition: all 0.3s;
    line-height: 32px;
    text-align: center;
    .anticon {
      color: rgba(0, 0, 0, 0.5);
    }
    &:hover {
      background: rgba(63, 135, 245, 0.15);
      color: $Theme-Blue-36;
      .anticon {
        color: $Theme-Blue-36;
      }
    }
  }
  .ant-table-pagination {
    margin: 16px 0 0;
    width: 100%;
    text-align: center;
    .ant-pagination-item {
      border: 1px solid $Border-Grey;
      color: rgba(114, 132, 154, 0.5);
      &:hover {
        a {
          color: $Theme-Blue-36;
        }
      }
    }
    .ant-pagination-item-active {
      border-color: $Theme-Blue-36;
      a {
        color: $Theme-Blue-36;
      }
    }
  }
}

.ant-select-dropdown {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  .ant-select-item {
    color: $Dark-Grey-72;
    font-weight: 600;
  }
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: none;
  }
}

.ant-form-item-has-error
  .ant-select:not(.ant-select-borderless)
  .ant-select-selector {
  border-color: $Border-Grey !important;
}

.search_filterDropdown {
  padding: 8px;
  .search_input {
    max-width: 188px;
    margin-bottom: 8px;
    display: block;
    border: 1px solid $Border-Grey;
    border-radius: 4px;
    font-size: 13px;
    padding: 5px 10px;
    width: 100%;
  }
  .btns {
    .ant-btn {
      width: 90px;
      border-radius: 4px;
      box-shadow: none;
      font-weight: 600;
      padding: 4px 6px;
      font-size: 13px;
      &:hover {
        border-color: $Theme-Blue-36;
        color: $Theme-Blue-36;
      }
    }
    .ant-btn-primary {
      background: $Theme-Blue-36;
      border-color: $Theme-Blue-36;
      &:hover {
        color: $White;
      }
    }
  }
}

.form_block_main {
  .formitem_row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    @media #{$PhoneL} {
      flex-direction: column;
    }
    .ant-form-item {
      flex-direction: column;
      width: 100%;
      padding: 0 10px;
      margin: 0 0 16px;
      .ant-form-item-label {
        text-align: left;
      }
    }
    .ant-form-item-label > label {
      color: $Dark-Grey-36;
      font-weight: 600;
      padding-bottom: 8px;
    }
    .ant-input,
    .ant-picker {
      border: 1px solid $Border-Grey-input;
      border-radius: 4px;
      font-size: 14px;
      padding: 8px 10px;
      width: 100%;
      background: $Light-Gray-fa;
      input {
        font-size: 14px;
      }
    }
    .ant-input-password {
      border: 1px solid $Border-Grey-input;
      border-radius: 4px;
      background: $Light-Gray-fa;
      .ant-input {
        border: none;
        padding: 0;
      }
    }
    .ant-select-selector {
      border: 1px solid $Border-Grey-input;
      border-radius: 4px;
      padding: 3.5px 12px;
      height: 40px;
      background: $Light-Gray-fa;
    }
  }
  .formitem_upload_row {
    .ant-form-item {
      flex-direction: column;
      width: 100%;
      padding: 0 10px;
      margin: 0;
      .ant-form-item-label {
        text-align: left;
      }
      .ant-form-item {
        padding: 0;
      }
    }
  }
  .formitem_action_row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid $Border-Grey;
    padding-top: 20px;
    margin: 0 10px;
    .ant-form-item {
      margin: 0;
      .ant-btn {
        margin: 0 0 0 10px;
        border-radius: 4px;
        box-shadow: none;
        font-weight: 600;
        &:hover {
          border-color: $Theme-Blue-36;
          color: $Theme-Blue-36;
        }
      }
      .ant-btn-primary {
        background: $Theme-Blue-36;
        border-color: $Theme-Blue-36;
        &:hover {
          color: $White;
        }
      }
    }
  }
}

.ant-checkbox-checked::after {
  border-color: $Theme-Blue-36;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: $Theme-Blue-36;
  border-color: $Theme-Blue-36;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background: $Light-Gray-f9;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: $Theme-Blue-36;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: $Theme-Blue-36;
}

.ant-tabs-ink-bar {
  background: $Theme-Blue-36;
}

.fixed_scroll_button {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 165px;
  bottom: 88px;
  right: 20%;
  @media #{$large-screen} {
    right: 24%;
  }
  @media #{$Small-Laptop} {
    right: 12%;
  }
  @media #{$IpadP} {
    right: auto;
    left: 35%;
  }
  @media #{$PhoneL} {
    left: 35px;
  }
}
.fixed_button {
  width: 100%;
  text-align: center;
  border-radius: 4px;
  background: $Theme-Blue-36;
  border-color: $Theme-Blue-36;
  box-shadow: none;
  font-weight: 600;
  max-width: 165px;
  &:hover {
    background: $Theme-Blue-36;
    border-color: $Theme-Blue-36;
  }
  &:focus {
    background: $Theme-Blue-36;
    border-color: $Theme-Blue-36;
  }
}

.ant-layout-footer {
  // position: fixed;
  // left: 220px;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  padding: 13px 25px;
  background: $White;
  border-top: 1px solid $Border-Grey;
  z-index: 1;
  font-size: 13px;
}
