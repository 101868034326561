$Dark-Blue: #0b1b47;
$White: #ffffff;
$Light-Gray: #f9fbfd;
$Light-Gray-f9: #f9f9f9;
$Light-Gray-fa: #fafafa;
$Border-Grey: #edf2f9;
$Border-Grey-input:#e4e8eb;
$Blue-3f:#3f87f5;
$Theme-Blue-36:#3653AA;
$Dark-Grey: #2a2a2a;
$Dark-Grey-36:#363636;
$Dark-Grey-53:#53535f;
$Dark-Grey-72:#72849a;
$Dark-Grey-91: #919da8;

$large-screen: "only screen and (min-width: 1440px)";
$Small-Laptop: "only screen and (max-width: 1199px)";
$IpadP: "only screen and (max-width: 1023px)";
$PhoneL: "only screen and (max-width: 767px)";