@import '../../variables.scss';

.loader {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.export_link {
  margin-left: 10px;
  border-radius: 4px;
  box-shadow: none;
  font-weight: 600;
  height: 40px;
  padding: 7px 15px;
  font-size: 16px;
  background-color: $White;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.6);
  &:hover {
    border: 1px solid $Theme-Blue-36;
    color: $Theme-Blue-36;
  }
}
.TableEditStyles {
  .ant-table-thead > tr > th .ant-table-filter-column-title {
    white-space: nowrap;
    flex: initial;
    padding-right: 0;
  }
  .ant-table-thead > tr > th {
    .ant-table-filter-trigger-container {
      position: static;
      background: none;
      .ant-table-filter-trigger {
        position: relative;
      }
    }
  }

  .ant-table-thead > tr > th:last-child,
  .ant-table tfoot > tr > th:last-child {
    background: $White;
    border-bottom: 1px solid $Border-Grey;
    right: 0;
    top: auto;
    position: sticky;
    z-index: 3;
    box-shadow: none;
  }

  .ant-table-tbody > tr > td:last-child,
  .ant-table tfoot > tr > td:last-child {
    background: $White;
    right: 0;
    top: auto;
    position: sticky;
    z-index: 3;
    box-shadow: none;
    &:hover {
      background: $White;
    }
  }

  .ant-table-thead {
    tr {
      &:hover {
        th:last-child {
          background: $Light-Gray-fa;
        }
      }
    }
  }
  .ant-table-tfoot {
    tr {
      &:hover {
        th:last-child {
          background: $Light-Gray-fa;
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        .emailid {
          color: $Theme-Blue-36;
        }
      }
      &:hover {
        td:last-child {
          background: $Light-Gray-fa;
        }
      }
    }
  }
  .ant-table-tfoot {
    tr {
      &:hover {
        td:last-child {
          background: $Light-Gray-fa;
        }
      }
    }
  }
}

.loader1 {
  position: fixed;
  left: 40%;
  top: 35%;
  z-index: 5;
}
.EditUserFormStyles {
  .EditUserForm_container {
    .EditUserForm__title {
      font-size: 20px;
      line-height: 22px;
      color: $Dark-Blue;
      text-transform: capitalize;
      padding: 0 0 20px;
    }
    .EditUserForm_whitebox {
      max-width: 60%;
      margin: 0 0 24px;
      @media #{$IpadP} {
        max-width: 100%;
      }
    }
  }
}
