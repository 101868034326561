@import '../../variables.scss';

.BusinessRulesStyles {
  .layout_middel_content {
    padding: 16px 24px;
    height: calc(100vh - 134px);
    overflow: hidden;
    overflow-y: auto;
  }
  .BusinessRules_container {
    .BusinessRules__title {
      font-size: 20px;
      line-height: 22px;
      color: $Dark-Blue;
      text-transform: capitalize;
      padding: 0 0 20px;
    }
    .BusinessRules_whitebox {
      max-width: 60%;
      @media #{$Small-Laptop} {
        max-width: 100%;
      }
      .formitem_row {
        @media #{$IpadP} {
          flex-wrap: wrap;
        }
      }
      .formitem_checkbox_row {
        padding: 0 10px 20px;
        .ant-form-item {
          margin: 0;
        }
      }
    }
  }
}
.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
