@import "../../variables.scss";

.ImportSampleOrderStyles {    

  .ImportSampleOrder_container {
    .ImportSampleOrder__title {
      font-size: 20px;
      line-height: 22px;
      color: $Dark-Blue;
      text-transform: capitalize;
      padding: 0 0 20px;
    }
    .ImportSampleOrder_whitebox {
      max-width: 60%;
      margin: 0 0 20px;
      @media #{$IpadP} {
        max-width: 100%;
       }
       .multiple_select {
        .ant-select-selector {
         height: auto;
        }
      }
    }
  }

}

.loader{
  width:100%;
  height:100vh;
  display:flex;
  align-items:center;
  justify-content:center;
}
