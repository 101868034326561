@import "../../variables.scss";

.FTSSettingsStyles {    
  .layout_middel_content {
    padding:16px 24px;
    height: calc(100vh - 134px);
    overflow: hidden;
    overflow-y: auto;
  }
  .FTSSettings_Tabs {
    border: 1px solid $Border-Grey;
    background: $White;
    padding:24px;
    margin: 0 0 24px;
    .ant-tabs-bar {
      margin: 0 0 24px;
      .ant-tabs-nav {
        .ant-tabs-tab {
          padding: 0 0 12px;
          &:hover {
            color: $Theme-Blue-36;
          }
        }
        .ant-tabs-tab-active {
          color: $Theme-Blue-36;
          font-weight: 600;
        }
      }
    }
  }
}
