@import '../../variables.scss';
.ant-table-content {
  @media #{$large-screen} {
    overflow-x: auto;
    max-width: 100%;
  }
}

.OrderTableEditStyles {
  .Table_top_row {
    padding: 0 0 10px;
    flex-wrap: wrap !important;
    @media #{$Small-Laptop} {
      flex-direction: column;
      align-items: flex-start;
    }
    .Table_action_btns {
      @media #{$Small-Laptop} {
        padding: 10px 0 0;
        flex: 0 0 100%;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
      }
      button {
        @media #{$Small-Laptop} {
          margin: 5px;
        }
      }
      .export_link {
        margin-left: 10px;
        border-radius: 4px;
        box-shadow: none;
        font-weight: 600;
        height: 40px;
        padding: 7px 15px;
        font-size: 16px;
        white-space: nowrap !important;
        background-color: $White;
        border: 1px solid #d9d9d9;
        color: rgba(0, 0, 0, 0.6);
        &:hover {
          border: 1px solid $Theme-Blue-36;
          color: $Theme-Blue-36;
        }
      }
    }
  }
  .table_search_block {
    // width: 100%;
    max-width: 440px;
    text-align: left;
    border: none;
    background: none;
    box-shadow: none;
    padding: 0 0;
    display: flex;
    align-items: center;
    @media #{$PhoneL} {
      width: 100%;
      max-width: 100%;
    }
    .anticon {
      font-size: 16px;
      color: $Dark-Grey-53;
      margin: 7px 0;
      opacity: 0.8;
      &:hover {
        background: none;
      }
    }
    input {
      border: none;
      background: none;
      box-shadow: none;
      color: $Dark-Grey-53;
      padding: 5px 4px;
      font-size: 16px;
      line-height: 20px;
    }
    .ant-btn {
      margin-left: 10px;
      @media #{$PhoneL} {
        margin-right: 6px;
        margin-top: 0;
      }
    }
  }

  .Table_block_main {
    .ant-table-thead > tr > th .ant-table-filter-column-title {
      white-space: nowrap;
      flex: initial;
      padding-right: 0;
    }
    .ant-table-thead > tr > th {
      .ant-table-filter-trigger-container {
        position: static;
        background: none;
        .ant-table-filter-trigger {
          position: relative;
        }
      }
    }
    .ant-table-tbody > tr > td {
      white-space: nowrap;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      // white-space: normal;
    }
  }

  .ant-table-thead > tr > th:last-child,
  .ant-table tfoot > tr > th:last-child {
    background: $White;
    border-bottom: 1px solid $Border-Grey;
    right: 0;
    top: auto;
    position: sticky;
    z-index: 3;
    box-shadow: none;
  }

  .ant-table-tbody > tr > td:last-child,
  .ant-table tfoot > tr > td:last-child {
    background: $White;
    right: 0;
    top: auto;
    position: sticky;
    z-index: 3;
    box-shadow: none;
    &:hover {
      background: $White;
    }
  }

  .ant-table-thead {
    tr {
      &:hover {
        th:last-child {
          background: $Light-Gray-fa;
        }
      }
    }
  }
  .ant-table-tfoot {
    tr {
      &:hover {
        th:last-child {
          background: $Light-Gray-fa;
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      &:hover {
        td:last-child {
          background: $Light-Gray-fa;
        }
      }
    }
  }
  .ant-table-tfoot {
    tr {
      &:hover {
        td:last-child {
          background: $Light-Gray-fa;
        }
      }
    }
  }
}

.AddOrderStyles {
  .AddOrder_container {
    .AddOrder__title {
      font-size: 20px;
      line-height: 22px;
      color: $Dark-Blue;
      text-transform: capitalize;
      padding: 0 0 20px;
    }
    .AddOrder_whitebox {
      max-width: 60%;
      margin: 0;
      @media #{$IpadP} {
        max-width: 100%;
      }
    }
  }
}

.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form_block_main {
  .formitem_row {
    .req_error {
      .ant-input,
      .ant-picker {
        border-color: #ff4d4f;
      }
    }
    .info_icon {
      position: relative;
      .anticon-info-circle {
        position: absolute;
        right: 15px;
        top: 5px;
      }
    }
  }
}
