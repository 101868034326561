@import "../../variables.scss";

.ImportSampleOrderApprovalStyles {    
    .ImportSampleOrderApproval_container {
      .ImportSampleOrderApproval__title {
        font-size: 20px;
        line-height: 22px;
        color: $Dark-Blue;
        text-transform: capitalize;
        padding: 0 0 20px;
      }
      .ImportSampleOrderApproval_whitebox {
        max-width: 60%;
        @media #{$IpadP} {
          max-width: 100%;
         }
      }
    }

    .noti_list_col_block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 1px solid $Border-Grey;
      .noti_info {
        flex-direction: column;
        .noti_msg {
          padding: 0 0 10px;
          display: block;
        }
      }
    }
}