@import "../../variables.scss";

.Modal_large {
  width:800px;
  @media (max-width: 992px) {
    width: 90%;
  }
}

.ant-modal-content {
  border-radius:4px;
  box-shadow: 0 4px 12px rgba(204, 127, 127, 0.15);
  .ant-modal-header {
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid $Border-Grey;
  }
  .ant-modal-body {
    padding: 24px 14px;
  }
  .modal_form_block {
    .modal_formitem_row {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      @media (max-width: 540px) {
        flex-direction: column;
    }
      .ant-form-item {
        flex-direction: column;
        width: 100%;
        padding: 0 10px;
        margin: 0 0 16px;
        .ant-form-item-label {
          text-align: left;
        }
        .ant-picker {
          width: 100%;
        }
      }
    }
    .modal_formitem_upload_row {
      .ant-form-item {
        flex-direction: column;
        width: 100%;
        padding: 0 10px;
        margin: 0;
        .ant-form-item-label {
          text-align: left;
        }
        .ant-form-item {
          padding: 0;
        }
      }
    }
  }
  .ant-form-item-label > label {
    color: $Dark-Grey-53;
    font-weight: 600;
    padding-bottom: 8px;
  }
  .ant-input,
  .ant-picker{
    border: 1px solid $Border-Grey;
    border-radius: 4px;
    font-size: 14px;
    padding: 7.5px 10px;
    input {
      font-size: 14px;
    }
  }
  .ant-select-selector {
    border: 1px solid $Border-Grey;
    border-radius: 4px;
    padding: 3.5px 12px;
    height:40px;
  }
  .ant-modal-footer {
    border-top: 1px solid $Border-Grey;
    .ant-btn {
      border-radius: 4px;
      box-shadow: none;
      font-weight: 600;
    }
    .ant-btn-primary {
      background: $Theme-Blue-36;
      border-color: $Theme-Blue-36;
    }
  }
}
