@import "../../variables.scss";

.HistoryTableStyles {
  .ant-table-content {
    @media #{$Small-Laptop} {
      overflow-x: auto;
      max-width: 1200px;
    }
  }

  .Table_block_main {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td, 
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      padding: 16px 8px;
    }

    .ant-table-thead > tr > th,
    .ant-table tfoot > tr > th {
      padding: 0 8px 12px 8px;
    }

    .ant-table-tbody > tr > td, 
    .ant-table tfoot > tr > td {
      .filename {
        font-weight: 600;
        color: $Dark-Grey-53;
      }
      .status {
        &.success {
          color: green;
        }
        &.fail {
          color: red;
        }
      }
    }

    .ant-table-thead > tr > th:first-child,
    .ant-table tfoot > tr > th:first-child {
      text-align: left;
    }

    .ant-table-tbody > tr > td:first-child {
      text-align: left;
    }
  }

  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: $Light-Gray-fa;
  }
}