@import url(https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f9fbfd;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  outline: none;
  font-family: "Overpass", sans-serif;
}
.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-layout-sider {
  background: #3653AA;
  border-right: none;
}
@media only screen and (max-width: 767px) {
  .ant-layout-sider {
    display: none;
  }
  .ant-layout-sider.sider-open {
    display: block;
  }
}
.ant-layout-sider .ant-menu {
  background: #3653AA;
  border-right: 1px solid #3653AA;
}
.ant-layout-sider .ant-menu li {
  font-size: 14px;
  color: #ffffff;
  background: none;
  line-height: 45px;
  height: 45px;
  font-weight: 600;
  margin-top: 5px;
  margin-bottom: 5px;
}
.ant-layout-sider .ant-menu li.ant-menu-item-selected {
  color: #ffffff;
  background: #3653AA;
}
.ant-layout-sider .ant-menu li:hover {
  color: #ffffff;
}
.ant-layout-sider .ant-menu li:after {
  border-color: #ffffff;
  left: 0;
  right: auto;
}
.ant-layout-sider .ant-menu li.ant-menu-item .anticon {
  font-size: 16px;
}
.ant-layout-sider .ant-menu li a {
  overflow: hidden;
}
.ant-layout-sider .ant-layout-sider-trigger {
  background: #3653AA;
}

.Sidebar_Header_Logo {
  width: 100%;
  height: auto;
  background: #ffffff;
  margin: 0 auto;
  text-align: center;
  padding: 7px 0 8px;
}
.Sidebar_Header_Logo img {
  width: 100%;
  max-width: 170px;
}

.site-layout {
  background: #f9f9f9;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.ant-layout-content {
  background: none;
  padding: 0;
}

.ant-layout-header {
  background: #ffffff;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #edf2f9;
  padding: 0 25px 0 15px;
  height: 63px;
}
.ant-layout-header .Header_left_column {
  display: flex;
  align-items: center;
}
.ant-layout-header .Header_left_column .anticon.trigger {
  font-size: 18px;
  color: #53535f;
  margin: 14px 0;
  transition: all 0.3s;
  padding: 10px;
  border-radius: 4px;
}
.ant-layout-header .Header_left_column .anticon.trigger:hover {
  background: rgba(63, 135, 245, 0.15);
  color: #3653AA;
}
.ant-layout-header .Header_left_column .anticon.trigger:hover .anticon-delete {
  color: #3653AA;
}
.ant-layout-header .Header_left_column .Search_col {
  width: 220px;
  text-align: left;
  border: none;
  background: none;
  box-shadow: none;
  padding: 3px 15px;
  display: flex;
  align-items: center;
}
.ant-layout-header .Header_left_column .Search_col .anticon {
  font-size: 18px;
  color: #53535f;
}
.ant-layout-header .Header_left_column .Search_col .anticon:hover {
  background: none;
}
.ant-layout-header .Header_left_column .Search_col input {
  border: none;
  background: none;
  box-shadow: none;
  color: #53535f;
  padding: 0 10px;
  font-size: 18px;
}
.ant-layout-header .Header_right_column {
  display: flex;
  align-items: center;
}
.ant-layout-header .Header_right_column .Noti_icon {
  padding-right: 10px;
  border-right: 1px solid #edf2f9;
  margin-right: 10px;
}
.ant-layout-header .Header_right_column .Noti_icon .anticon {
  font-size: 20px;
  color: #53535f;
  margin: 14px 0;
  transition: all 0.3s;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}
.ant-layout-header .Header_right_column .Noti_icon .anticon:hover {
  background: rgba(63, 135, 245, 0.15);
  color: #3653AA;
}
.ant-layout-header .Header_right_column .Noti_icon .anticon:hover .anticon-delete {
  color: #3653AA;
}
.ant-layout-header .Header_right_column .Header_user_avatar .ant-dropdown-link {
  display: inline-block;
}
.ant-layout-header .Header_right_column .Header_user_avatar .Header_username {
  color: #53535f;
  padding-left: 5px;
  font-weight: 600;
  text-transform: capitalize;
}
.ant-layout-header .Header_right_column .Header_user_avatar .anticon {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}

.notification__dropdown_main {
  padding: 0;
}
.notification__dropdown_main .noti_list_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #edf2f9;
}
.notification__dropdown_main .noti_list_block:last-child {
  border: none;
}
.notification__dropdown_main .noti_list_block:hover {
  background-color: #fafafa;
}
.notification__dropdown_main .noti_list_block .noti_list_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.notification__dropdown_main .noti_list_block .noti_list_row .noti_list_title_left .anticon {
  font-size: 16px;
  margin-right: 10px;
}
.notification__dropdown_main .noti_list_block .noti_list_row .noti_list_title_left span {
  font-size: 16px;
  line-height: 18px;
  color: #2a2a2a;
  font-weight: 600;
}
.notification__dropdown_main .noti_list_block .noti_list_row .noti_viewall .view_all_btn {
  color: #3653AA;
  font-size: 13px;
}
.notification__dropdown_main .noti_list_block .noti_list_row .ant-avatar {
  height: 40px;
  width: 40px;
}
.notification__dropdown_main .noti_list_block .noti_list_row .noti_info {
  flex-direction: column;
  padding-left: 12px;
}
.notification__dropdown_main .noti_list_block .noti_list_row .noti_info .noti_msg {
  padding: 0 0 5px;
  display: block;
  color: #2a2a2a;
}
.notification__dropdown_main .noti_list_block .noti_list_row .noti_info .noti_date {
  color: #72849a;
  font-size: 13px;
}

.profile_dropdown_main {
  padding: 0;
  border-radius: 4px;
  padding-bottom: 10px;
}
.profile_dropdown_main .profile_list_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 20px;
}
.profile_dropdown_main .profile_list_block:first-child {
  border-bottom: 1px solid #edf2f9;
  padding: 18px 20px;
  margin-bottom: 10px;
}
.profile_dropdown_main .profile_list_block:hover {
  background-color: #fafafa;
}
.profile_dropdown_main .profile_list_block .profile_list_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.profile_dropdown_main .profile_list_block .profile_list_row .profile_user_avatar .ant-avatar {
  height: 48px;
  width: 48px;
  background: #edf2f9;
}
.profile_dropdown_main .profile_list_block .profile_list_row .profile_user_details {
  flex-direction: column;
  padding-left: 10px;
}
.profile_dropdown_main .profile_list_block .profile_list_row .profile_user_details .profile_username {
  text-transform: capitalize;
  padding: 0 0 6px;
  font-size: 16px;
  line-height: 18px;
  color: #2a2a2a;
}
.profile_dropdown_main .profile_list_block .profile_list_row .profile_user_details .profile_user_position {
  color: #72849a;
  opacity: 0.7;
  font-size: 15px;
}
.profile_dropdown_main .profile_list_block .anticon {
  font-size: 16px;
  margin-right: 10px;
  opacity: 0.4;
}
.profile_dropdown_main .profile_list_block span {
  color: #363636;
  font-size: 15px;
}

.ant-breadcrumb {
  margin: 0 0 16px 0;
  border-bottom: 1px solid #edf2f9;
  padding: 0 0 8px;
}
.ant-breadcrumb span {
  color: #72849a;
  font-weight: 600;
}
.ant-breadcrumb span:last-child {
  opacity: 0.7;
}

.layout_content_box {
  border: 1px solid #edf2f9;
  background: #ffffff;
  padding: 16px;
}

.Table_top_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 16px;
}
@media only screen and (max-width: 767px) {
  .Table_top_row {
    flex-wrap: wrap;
  }
}
.Table_top_row .Table_heading {
  font-size: 20px;
  line-height: 22px;
  color: #0b1b47;
  text-transform: capitalize;
  font-weight: 600;
}
@media only screen and (max-width: 1023px) {
  .Table_top_row .Table_heading {
    font-size: 22px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .Table_top_row .Table_heading {
    font-size: 20px;
    line-height: 22px;
    margin: 12px 0 0;
    white-space: nowrap;
  }
}
.Table_top_row .Table_action_btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 auto;
  padding-left: 16px;
}
.Table_top_row .Table_action_btns button {
  margin-left: 10px;
  border-radius: 4px;
  box-shadow: none;
  font-weight: 600;
}
@media only screen and (max-width: 767px) {
  .Table_top_row .Table_action_btns button {
    margin: 12px 6px 0 0;
  }
}
.Table_top_row .Table_action_btns button:hover {
  border-color: #3653AA;
  color: #3653AA;
}
.Table_top_row .Table_action_btns .ant-btn-primary {
  background: #3653AA;
  border-color: #3653AA;
  color: #ffffff;
}
.Table_top_row .Table_action_btns .ant-btn-primary:hover {
  color: #ffffff;
}

.Table_block_main .ant-table {
  background: transparent;
  mask-image: linear-gradient(to top, transparent 0%, rgb(255, 255, 255) 3%, rgb(255, 255, 255) 100%, transparent 100%);
  mask-size: cover;
  -webkit-mask-image: linear-gradient(to top, transparent 0%, rgb(255, 255, 255) 3%, rgb(255, 255, 255) 100%, transparent 100%);
  -webkit-mask-size: cover;
}
.Table_block_main .ant-table-thead > tr > th,
.Table_block_main .ant-table-tbody > tr > td,
.Table_block_main .ant-table tfoot > tr > th,
.Table_block_main .ant-table tfoot > tr > td {
  padding: 10px 8px;
  white-space: nowrap;
  border: none;
  border-right: none !important;
}
.Table_block_main .ant-table-thead > tr > th,
.Table_block_main .ant-table tfoot > tr > th {
  background: #ffffff;
  border-bottom: 1px solid #edf2f9;
  text-transform: capitalize;
  padding: 0 8px 12px 8px;
  color: #919da8;
  font-weight: 600;
}
.Table_block_main .ant-table-thead > tr > th.filtered_text {
  color: #ff4d4f;
}
.Table_block_main .ant-table-tbody > tr > td,
.Table_block_main .ant-table tfoot > tr > td {
  border-bottom: 1px solid #edf2f9;
  font-size: 14px;
  color: #53535f;
  transition: all 0.3s, height 0s;
}
.Table_block_main .ant-table-tbody > tr.ant-table-row:hover > td {
  background: #f9f9f9;
}
.Table_block_main .ant-table-tbody > tr.ant-table-row-selected > td {
  background: #fafafa;
}
.Table_block_main .ant-table-cell-fix-left,
.Table_block_main .ant-table-cell-fix-right {
  right: 0 !important;
  top: auto !important;
}
.Table_block_main .ant-table-ping-right .ant-table-cell-fix-right-first::after,
.Table_block_main .ant-table-ping-right .ant-table-cell-fix-right-last::after {
  box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
}
.Table_block_main .ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
}
.Table_block_main .ant-table-ping-left .ant-table-cell-fix-left-first::after,
.Table_block_main .ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: none;
  -webkit-box-shadow: none;
  border: none;
}
.Table_block_main .ant-table.ant-table-bordered .ant-table-cell-fix-right-first::after {
  border: none;
}
@media only screen and (max-width: 1023px) {
  .Table_block_main .ant-table-content {
    overflow-x: auto;
    max-width: 1024px;
  }
}
.Table_block_main .ant-table-bordered .ant-table-container {
  border: none;
}
.Table_block_main .editable-cell {
  position: relative;
}
.Table_block_main .editable-cell-value-wrap {
  padding: 8px 12px;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
  transition: all 0.3s;
  min-width: 115px;
}
.Table_block_main .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #edf2f9;
  border-radius: 4px;
  padding: 8px 12px;
  background: #ffffff;
}
.Table_block_main .editable-row .ant-form-item input {
  border: 1px solid #edf2f9;
  border-radius: 4px;
  padding: 8px 12px;
  background: #ffffff;
  transition: all 0.3s;
  color: #72849a;
  min-width: 115px;
}
.Table_block_main [data-theme=dark] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}
.Table_block_main .ant-table-cell .ant-select-selector {
  background: #ffffff;
  color: rgba(114, 132, 154, 0.5);
  border: 1px solid #edf2f9;
  border-radius: 4px;
  height: 40px;
  padding: 4px 11px;
}
.Table_block_main .ant-table-cell:first-child {
  font-weight: 600;
}
.Table_block_main .ant-table-cell:first-child .ant-form-item input {
  font-weight: 600;
}
.Table_block_main .ant-table-cell .ant-select-open .ant-select-selector .ant-select-selection-item {
  opacity: 1;
}
.Table_block_main .ico_delete,
.Table_block_main .ico_save,
.Table_block_main .ico_edit {
  height: 32px;
  width: 32px;
  display: inline-block;
  border-radius: 50%;
  background: transparent;
  transition: all 0.3s;
  line-height: 32px;
  text-align: center;
}
.Table_block_main .ico_delete .anticon,
.Table_block_main .ico_save .anticon,
.Table_block_main .ico_edit .anticon {
  color: rgba(0, 0, 0, 0.5);
}
.Table_block_main .ico_delete:hover,
.Table_block_main .ico_save:hover,
.Table_block_main .ico_edit:hover {
  background: rgba(63, 135, 245, 0.15);
  color: #3653AA;
}
.Table_block_main .ico_delete:hover .anticon,
.Table_block_main .ico_save:hover .anticon,
.Table_block_main .ico_edit:hover .anticon {
  color: #3653AA;
}
.Table_block_main .ant-table-pagination {
  margin: 16px 0 0;
  width: 100%;
  text-align: center;
}
.Table_block_main .ant-table-pagination .ant-pagination-item {
  border: 1px solid #edf2f9;
  color: rgba(114, 132, 154, 0.5);
}
.Table_block_main .ant-table-pagination .ant-pagination-item:hover a {
  color: #3653AA;
}
.Table_block_main .ant-table-pagination .ant-pagination-item-active {
  border-color: #3653AA;
}
.Table_block_main .ant-table-pagination .ant-pagination-item-active a {
  color: #3653AA;
}

.ant-select-dropdown {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.ant-select-dropdown .ant-select-item {
  color: #72849a;
  font-weight: 600;
}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: none;
}

.ant-form-item-has-error .ant-select:not(.ant-select-borderless) .ant-select-selector {
  border-color: #edf2f9 !important;
}

.search_filterDropdown {
  padding: 8px;
}
.search_filterDropdown .search_input {
  max-width: 188px;
  margin-bottom: 8px;
  display: block;
  border: 1px solid #edf2f9;
  border-radius: 4px;
  font-size: 13px;
  padding: 5px 10px;
  width: 100%;
}
.search_filterDropdown .btns .ant-btn {
  width: 90px;
  border-radius: 4px;
  box-shadow: none;
  font-weight: 600;
  padding: 4px 6px;
  font-size: 13px;
}
.search_filterDropdown .btns .ant-btn:hover {
  border-color: #3653AA;
  color: #3653AA;
}
.search_filterDropdown .btns .ant-btn-primary {
  background: #3653AA;
  border-color: #3653AA;
}
.search_filterDropdown .btns .ant-btn-primary:hover {
  color: #ffffff;
}

.form_block_main .formitem_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .form_block_main .formitem_row {
    flex-direction: column;
  }
}
.form_block_main .formitem_row .ant-form-item {
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
  margin: 0 0 16px;
}
.form_block_main .formitem_row .ant-form-item .ant-form-item-label {
  text-align: left;
}
.form_block_main .formitem_row .ant-form-item-label > label {
  color: #363636;
  font-weight: 600;
  padding-bottom: 8px;
}
.form_block_main .formitem_row .ant-input,
.form_block_main .formitem_row .ant-picker {
  border: 1px solid #e4e8eb;
  border-radius: 4px;
  font-size: 14px;
  padding: 8px 10px;
  width: 100%;
  background: #fafafa;
}
.form_block_main .formitem_row .ant-input input,
.form_block_main .formitem_row .ant-picker input {
  font-size: 14px;
}
.form_block_main .formitem_row .ant-input-password {
  border: 1px solid #e4e8eb;
  border-radius: 4px;
  background: #fafafa;
}
.form_block_main .formitem_row .ant-input-password .ant-input {
  border: none;
  padding: 0;
}
.form_block_main .formitem_row .ant-select-selector {
  border: 1px solid #e4e8eb;
  border-radius: 4px;
  padding: 3.5px 12px;
  height: 40px;
  background: #fafafa;
}
.form_block_main .formitem_upload_row .ant-form-item {
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
  margin: 0;
}
.form_block_main .formitem_upload_row .ant-form-item .ant-form-item-label {
  text-align: left;
}
.form_block_main .formitem_upload_row .ant-form-item .ant-form-item {
  padding: 0;
}
.form_block_main .formitem_action_row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #edf2f9;
  padding-top: 20px;
  margin: 0 10px;
}
.form_block_main .formitem_action_row .ant-form-item {
  margin: 0;
}
.form_block_main .formitem_action_row .ant-form-item .ant-btn {
  margin: 0 0 0 10px;
  border-radius: 4px;
  box-shadow: none;
  font-weight: 600;
}
.form_block_main .formitem_action_row .ant-form-item .ant-btn:hover {
  border-color: #3653AA;
  color: #3653AA;
}
.form_block_main .formitem_action_row .ant-form-item .ant-btn-primary {
  background: #3653AA;
  border-color: #3653AA;
}
.form_block_main .formitem_action_row .ant-form-item .ant-btn-primary:hover {
  color: #ffffff;
}

.ant-checkbox-checked::after {
  border-color: #3653AA;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background: #3653AA;
  border-color: #3653AA;
}

.ant-checkbox-disabled .ant-checkbox-inner {
  background: #f9f9f9;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #3653AA;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #3653AA;
}

.ant-tabs-ink-bar {
  background: #3653AA;
}

.fixed_scroll_button {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 165px;
  bottom: 88px;
  right: 20%;
}
@media only screen and (min-width: 1440px) {
  .fixed_scroll_button {
    right: 24%;
  }
}
@media only screen and (max-width: 1199px) {
  .fixed_scroll_button {
    right: 12%;
  }
}
@media only screen and (max-width: 1023px) {
  .fixed_scroll_button {
    right: auto;
    left: 35%;
  }
}
@media only screen and (max-width: 767px) {
  .fixed_scroll_button {
    left: 35px;
  }
}

.fixed_button {
  width: 100%;
  text-align: center;
  border-radius: 4px;
  background: #3653AA;
  border-color: #3653AA;
  box-shadow: none;
  font-weight: 600;
  max-width: 165px;
}
.fixed_button:hover {
  background: #3653AA;
  border-color: #3653AA;
}
.fixed_button:focus {
  background: #3653AA;
  border-color: #3653AA;
}

.ant-layout-footer {
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  padding: 13px 25px;
  background: #ffffff;
  border-top: 1px solid #edf2f9;
  z-index: 1;
  font-size: 13px;
}
.SampleOrderStyles .layout_middel_content {
  padding: 16px 24px 0;
  height: calc(100vh - 134px);
  overflow: hidden;
  overflow-y: auto;
}
@media only screen and (min-width: 1440px) {
  .ant-table-content {
    overflow-x: auto;
    max-width: 100%;
  }
}

.OrderTableEditStyles .Table_top_row {
  padding: 0 0 10px;
  flex-wrap: wrap !important;
}
@media only screen and (max-width: 1199px) {
  .OrderTableEditStyles .Table_top_row {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media only screen and (max-width: 1199px) {
  .OrderTableEditStyles .Table_top_row .Table_action_btns {
    padding: 10px 0 0;
    flex: 0 0 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 1199px) {
  .OrderTableEditStyles .Table_top_row .Table_action_btns button {
    margin: 5px;
  }
}
.OrderTableEditStyles .Table_top_row .Table_action_btns .export_link {
  margin-left: 10px;
  border-radius: 4px;
  box-shadow: none;
  font-weight: 600;
  height: 40px;
  padding: 7px 15px;
  font-size: 16px;
  white-space: nowrap !important;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.6);
}
.OrderTableEditStyles .Table_top_row .Table_action_btns .export_link:hover {
  border: 1px solid #3653AA;
  color: #3653AA;
}
.OrderTableEditStyles .table_search_block {
  max-width: 440px;
  text-align: left;
  border: none;
  background: none;
  box-shadow: none;
  padding: 0 0;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .OrderTableEditStyles .table_search_block {
    width: 100%;
    max-width: 100%;
  }
}
.OrderTableEditStyles .table_search_block .anticon {
  font-size: 16px;
  color: #53535f;
  margin: 7px 0;
  opacity: 0.8;
}
.OrderTableEditStyles .table_search_block .anticon:hover {
  background: none;
}
.OrderTableEditStyles .table_search_block input {
  border: none;
  background: none;
  box-shadow: none;
  color: #53535f;
  padding: 5px 4px;
  font-size: 16px;
  line-height: 20px;
}
.OrderTableEditStyles .table_search_block .ant-btn {
  margin-left: 10px;
}
@media only screen and (max-width: 767px) {
  .OrderTableEditStyles .table_search_block .ant-btn {
    margin-right: 6px;
    margin-top: 0;
  }
}
.OrderTableEditStyles .Table_block_main .ant-table-thead > tr > th .ant-table-filter-column-title {
  white-space: nowrap;
  flex: initial;
  padding-right: 0;
}
.OrderTableEditStyles .Table_block_main .ant-table-thead > tr > th .ant-table-filter-trigger-container {
  position: static;
  background: none;
}
.OrderTableEditStyles .Table_block_main .ant-table-thead > tr > th .ant-table-filter-trigger-container .ant-table-filter-trigger {
  position: relative;
}
.OrderTableEditStyles .Table_block_main .ant-table-tbody > tr > td {
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.OrderTableEditStyles .ant-table-thead > tr > th:last-child,
.OrderTableEditStyles .ant-table tfoot > tr > th:last-child {
  background: #ffffff;
  border-bottom: 1px solid #edf2f9;
  right: 0;
  top: auto;
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  box-shadow: none;
}
.OrderTableEditStyles .ant-table-tbody > tr > td:last-child,
.OrderTableEditStyles .ant-table tfoot > tr > td:last-child {
  background: #ffffff;
  right: 0;
  top: auto;
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  box-shadow: none;
}
.OrderTableEditStyles .ant-table-tbody > tr > td:last-child:hover,
.OrderTableEditStyles .ant-table tfoot > tr > td:last-child:hover {
  background: #ffffff;
}
.OrderTableEditStyles .ant-table-thead tr:hover th:last-child {
  background: #fafafa;
}
.OrderTableEditStyles .ant-table-tfoot tr:hover th:last-child {
  background: #fafafa;
}
.OrderTableEditStyles .ant-table-tbody tr:hover td:last-child {
  background: #fafafa;
}
.OrderTableEditStyles .ant-table-tfoot tr:hover td:last-child {
  background: #fafafa;
}

.AddOrderStyles .AddOrder_container .AddOrder__title {
  font-size: 20px;
  line-height: 22px;
  color: #0b1b47;
  text-transform: capitalize;
  padding: 0 0 20px;
}
.AddOrderStyles .AddOrder_container .AddOrder_whitebox {
  max-width: 60%;
  margin: 0;
}
@media only screen and (max-width: 1023px) {
  .AddOrderStyles .AddOrder_container .AddOrder_whitebox {
    max-width: 100%;
  }
}

.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form_block_main .formitem_row .req_error .ant-input,
.form_block_main .formitem_row .req_error .ant-picker {
  border-color: #ff4d4f;
}
.form_block_main .formitem_row .info_icon {
  position: relative;
}
.form_block_main .formitem_row .info_icon .anticon-info-circle {
  position: absolute;
  right: 15px;
  top: 5px;
}
.Modal_large {
  width: 800px;
}
@media (max-width: 992px) {
  .Modal_large {
    width: 90%;
  }
}

.ant-modal-content {
  border-radius: 4px;
  box-shadow: 0 4px 12px rgba(204, 127, 127, 0.15);
}
.ant-modal-content .ant-modal-header {
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid #edf2f9;
}
.ant-modal-content .ant-modal-body {
  padding: 24px 14px;
}
.ant-modal-content .modal_form_block .modal_formitem_row {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 540px) {
  .ant-modal-content .modal_form_block .modal_formitem_row {
    flex-direction: column;
  }
}
.ant-modal-content .modal_form_block .modal_formitem_row .ant-form-item {
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
  margin: 0 0 16px;
}
.ant-modal-content .modal_form_block .modal_formitem_row .ant-form-item .ant-form-item-label {
  text-align: left;
}
.ant-modal-content .modal_form_block .modal_formitem_row .ant-form-item .ant-picker {
  width: 100%;
}
.ant-modal-content .modal_form_block .modal_formitem_upload_row .ant-form-item {
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
  margin: 0;
}
.ant-modal-content .modal_form_block .modal_formitem_upload_row .ant-form-item .ant-form-item-label {
  text-align: left;
}
.ant-modal-content .modal_form_block .modal_formitem_upload_row .ant-form-item .ant-form-item {
  padding: 0;
}
.ant-modal-content .ant-form-item-label > label {
  color: #53535f;
  font-weight: 600;
  padding-bottom: 8px;
}
.ant-modal-content .ant-input,
.ant-modal-content .ant-picker {
  border: 1px solid #edf2f9;
  border-radius: 4px;
  font-size: 14px;
  padding: 7.5px 10px;
}
.ant-modal-content .ant-input input,
.ant-modal-content .ant-picker input {
  font-size: 14px;
}
.ant-modal-content .ant-select-selector {
  border: 1px solid #edf2f9;
  border-radius: 4px;
  padding: 3.5px 12px;
  height: 40px;
}
.ant-modal-content .ant-modal-footer {
  border-top: 1px solid #edf2f9;
}
.ant-modal-content .ant-modal-footer .ant-btn {
  border-radius: 4px;
  box-shadow: none;
  font-weight: 600;
}
.ant-modal-content .ant-modal-footer .ant-btn-primary {
  background: #3653AA;
  border-color: #3653AA;
}
.DPLsreeningTableStyles .Table_top_row {
  padding: 0 0 10px;
}
@media only screen and (max-width: 1023px) {
  .DPLsreeningTableStyles .Table_top_row {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media only screen and (max-width: 1023px) {
  .DPLsreeningTableStyles .Table_top_row .Table_action_btns {
    padding: 10px 0 0;
    flex: 0 0 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.DPLsreeningTableStyles .Table_top_row .Table_action_btns .export_link {
  margin-left: 10px;
  border-radius: 4px;
  box-shadow: none;
  font-weight: 600;
  height: 40px;
  padding: 7px 15px;
  font-size: 16px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.6);
}
.DPLsreeningTableStyles .Table_top_row .Table_action_btns .export_link:hover {
  border: 1px solid #3653AA;
  color: #3653AA;
}
.DPLsreeningTableStyles .table_search_block {
  max-width: 380px;
  text-align: left;
  border: none;
  background: none;
  box-shadow: none;
  padding: 0;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .DPLsreeningTableStyles .table_search_block {
    max-width: 100%;
  }
}
.DPLsreeningTableStyles .table_search_block .anticon {
  font-size: 16px;
  color: #53535f;
  margin: 7px 0;
  opacity: 0.8;
}
.DPLsreeningTableStyles .table_search_block .anticon:hover {
  background: none;
}
.DPLsreeningTableStyles .table_search_block input {
  border: none;
  background: none;
  box-shadow: none;
  color: #53535f;
  padding: 5px 4px;
  font-size: 16px;
  line-height: 20px;
}
.DPLsreeningTableStyles .table_search_block .ant-btn {
  margin-left: 10px;
}
@media only screen and (max-width: 767px) {
  .DPLsreeningTableStyles .table_search_block .ant-btn {
    margin-right: 6px;
    margin-top: 0;
  }
}
@media only screen and (min-width: 1440px) {
  .DPLsreeningTableStyles .ant-table-content {
    overflow-x: auto;
    max-width: 100%;
  }
}
.DPLsreeningTableStyles .Table_block_main .ant-table-thead > tr > th .ant-table-filter-column-title {
  white-space: nowrap;
  flex: initial;
  padding-right: 0;
}
.DPLsreeningTableStyles .Table_block_main .ant-table-thead > tr > th .ant-table-filter-trigger-container {
  position: static;
  background: none;
}
.DPLsreeningTableStyles .Table_block_main .ant-table-thead > tr > th .ant-table-filter-trigger-container .ant-table-filter-trigger {
  position: relative;
}
.DPLsreeningTableStyles .Table_block_main .ant-table-tbody > tr > td {
  white-space: normal;
}
.DPLsreeningTableStyles .Table_block_main .ant-table-tbody > tr > td.ellipsis_text {
  white-space: nowrap;
}
.DPLsreeningTableStyles .ant-table-thead > tr > th:last-child,
.DPLsreeningTableStyles .ant-table tfoot > tr > th:last-child {
  background: #ffffff;
  border-bottom: 1px solid #edf2f9;
  right: 0;
  top: auto;
  position: static;
  z-index: 3;
  box-shadow: none;
}
.DPLsreeningTableStyles .ant-table-tbody > tr > td:last-child,
.DPLsreeningTableStyles .ant-table tfoot > tr > td:last-child {
  background: #ffffff;
  right: 0;
  top: auto;
  position: static;
  z-index: 3;
  box-shadow: none;
}
.DPLsreeningTableStyles .ant-table-thead tr:hover th:last-child {
  background: #fafafa;
}
.DPLsreeningTableStyles .ant-table-tfoot tr:hover th:last-child {
  background: #fafafa;
}
.DPLsreeningTableStyles .ant-table-tbody tr:hover td:last-child {
  background: #fafafa;
}
.DPLsreeningTableStyles .ant-table-tfoot tr:hover td:last-child {
  background: #fafafa;
}

.AddCatalogStyles .AddCatalog_container .AddCatalog__title {
  font-size: 20px;
  line-height: 22px;
  color: #0b1b47;
  text-transform: capitalize;
  padding: 0 0 20px;
}
.AddCatalogStyles .AddCatalog_container .AddCatalog_whitebox {
  max-width: 60%;
  margin: 0;
}
@media only screen and (max-width: 1023px) {
  .AddCatalogStyles .AddCatalog_container .AddCatalog_whitebox {
    max-width: 100%;
  }
}

.loader1 {
  position: fixed;
  left: 40%;
  top: 35%;
  z-index: 5;
}
.UserManagementStyles .layout_middel_content {
  padding: 16px 24px;
  height: calc(100vh - 134px);
  overflow: hidden;
  overflow-y: auto;
}
.loader {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.export_link {
  margin-left: 10px;
  border-radius: 4px;
  box-shadow: none;
  font-weight: 600;
  height: 40px;
  padding: 7px 15px;
  font-size: 16px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.6);
}
.export_link:hover {
  border: 1px solid #3653AA;
  color: #3653AA;
}

.TableEditStyles .ant-table-thead > tr > th .ant-table-filter-column-title {
  white-space: nowrap;
  flex: initial;
  padding-right: 0;
}
.TableEditStyles .ant-table-thead > tr > th .ant-table-filter-trigger-container {
  position: static;
  background: none;
}
.TableEditStyles .ant-table-thead > tr > th .ant-table-filter-trigger-container .ant-table-filter-trigger {
  position: relative;
}
.TableEditStyles .ant-table-thead > tr > th:last-child,
.TableEditStyles .ant-table tfoot > tr > th:last-child {
  background: #ffffff;
  border-bottom: 1px solid #edf2f9;
  right: 0;
  top: auto;
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  box-shadow: none;
}
.TableEditStyles .ant-table-tbody > tr > td:last-child,
.TableEditStyles .ant-table tfoot > tr > td:last-child {
  background: #ffffff;
  right: 0;
  top: auto;
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  box-shadow: none;
}
.TableEditStyles .ant-table-tbody > tr > td:last-child:hover,
.TableEditStyles .ant-table tfoot > tr > td:last-child:hover {
  background: #ffffff;
}
.TableEditStyles .ant-table-thead tr:hover th:last-child {
  background: #fafafa;
}
.TableEditStyles .ant-table-tfoot tr:hover th:last-child {
  background: #fafafa;
}
.TableEditStyles .ant-table-tbody tr td .emailid {
  color: #3653AA;
}
.TableEditStyles .ant-table-tbody tr:hover td:last-child {
  background: #fafafa;
}
.TableEditStyles .ant-table-tfoot tr:hover td:last-child {
  background: #fafafa;
}

.loader1 {
  position: fixed;
  left: 40%;
  top: 35%;
  z-index: 5;
}

.EditUserFormStyles .EditUserForm_container .EditUserForm__title {
  font-size: 20px;
  line-height: 22px;
  color: #0b1b47;
  text-transform: capitalize;
  padding: 0 0 20px;
}
.EditUserFormStyles .EditUserForm_container .EditUserForm_whitebox {
  max-width: 60%;
  margin: 0 0 24px;
}
@media only screen and (max-width: 1023px) {
  .EditUserFormStyles .EditUserForm_container .EditUserForm_whitebox {
    max-width: 100%;
  }
}
.CatalogManagementStyles .layout_middel_content {
  padding: 16px 24px 0;
  height: calc(100vh - 134px);
  overflow: hidden;
  overflow-y: auto;
}
.CatalogTableEditStyles .Table_top_row {
  padding: 0 0 10px;
}
@media only screen and (max-width: 1023px) {
  .CatalogTableEditStyles .Table_top_row {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media only screen and (max-width: 1023px) {
  .CatalogTableEditStyles .Table_top_row .Table_action_btns {
    padding: 10px 0 0;
    flex: 0 0 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.CatalogTableEditStyles .Table_top_row .Table_action_btns .export_link {
  margin-left: 10px;
  border-radius: 4px;
  box-shadow: none;
  font-weight: 600;
  height: 40px;
  white-space: nowrap;
  padding: 7px 15px;
  font-size: 16px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.6);
}
.CatalogTableEditStyles .Table_top_row .Table_action_btns .export_link:hover {
  border: 1px solid #3653AA;
  color: #3653AA;
}
.CatalogTableEditStyles .table_search_block {
  max-width: 380px;
  text-align: left;
  border: none;
  background: none;
  box-shadow: none;
  padding: 0;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .CatalogTableEditStyles .table_search_block {
    max-width: 100%;
  }
}
.CatalogTableEditStyles .table_search_block .anticon {
  font-size: 16px;
  color: #53535f;
  margin: 7px 0;
  opacity: 0.8;
}
.CatalogTableEditStyles .table_search_block .anticon:hover {
  background: none;
}
.CatalogTableEditStyles .table_search_block input {
  border: none;
  background: none;
  box-shadow: none;
  color: #53535f;
  padding: 5px 4px;
  font-size: 16px;
  line-height: 20px;
}
.CatalogTableEditStyles .table_search_block .ant-btn {
  margin-left: 10px;
}
@media only screen and (max-width: 767px) {
  .CatalogTableEditStyles .table_search_block .ant-btn {
    margin-right: 6px;
    margin-top: 0;
  }
}
@media only screen and (min-width: 1440px) {
  .CatalogTableEditStyles .ant-table-content {
    overflow-x: auto;
    max-width: 100%;
  }
}
.CatalogTableEditStyles .Table_block_main .ant-table-thead > tr > th .ant-table-filter-column-title {
  white-space: nowrap;
  flex: initial;
  padding-right: 0;
}
.CatalogTableEditStyles .Table_block_main .ant-table-thead > tr > th .ant-table-filter-trigger-container {
  position: static;
  background: none;
}
.CatalogTableEditStyles .Table_block_main .ant-table-thead > tr > th .ant-table-filter-trigger-container .ant-table-filter-trigger {
  position: relative;
}
.CatalogTableEditStyles .Table_block_main .ant-table-tbody > tr > td {
  white-space: normal;
}
.CatalogTableEditStyles .ant-table-thead > tr > th:last-child,
.CatalogTableEditStyles .ant-table tfoot > tr > th:last-child {
  background: #ffffff;
  border-bottom: 1px solid #edf2f9;
  right: 0;
  top: auto;
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  box-shadow: none;
}
.CatalogTableEditStyles .ant-table-tbody > tr > td:last-child,
.CatalogTableEditStyles .ant-table tfoot > tr > td:last-child {
  background: #ffffff;
  right: 0;
  top: auto;
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  box-shadow: none;
}
.CatalogTableEditStyles .ant-table-thead tr:hover th:last-child {
  background: #fafafa;
}
.CatalogTableEditStyles .ant-table-tfoot tr:hover th:last-child {
  background: #fafafa;
}
.CatalogTableEditStyles .ant-table-tbody tr:hover td:last-child {
  background: #fafafa;
}
.CatalogTableEditStyles .ant-table-tfoot tr:hover td:last-child {
  background: #fafafa;
}

.AddCatalogStyles .AddCatalog_container .AddCatalog__title {
  font-size: 20px;
  line-height: 22px;
  color: #0b1b47;
  text-transform: capitalize;
  padding: 0 0 20px;
}
.AddCatalogStyles .AddCatalog_container .AddCatalog_whitebox {
  max-width: 60%;
  margin: 0;
}
@media only screen and (max-width: 1023px) {
  .AddCatalogStyles .AddCatalog_container .AddCatalog_whitebox {
    max-width: 100%;
  }
}

.loader1 {
  position: fixed;
  left: 40%;
  top: 35%;
  z-index: 5;
}

.ant-table-body {
  overflow: auto;
  max-height: 100% !important;
  height: calc(100vh - 375px) !important;
}
.ManageDistributionStyles .layout_middel_content {
  padding: 16px 24px;
  height: calc(100vh - 134px);
  overflow: hidden;
  overflow-y: auto;
}
.BusinessRulesStyles .layout_middel_content {
  padding: 16px 24px;
  height: calc(100vh - 134px);
  overflow: hidden;
  overflow-y: auto;
}
.BusinessRulesStyles .BusinessRules_container .BusinessRules__title {
  font-size: 20px;
  line-height: 22px;
  color: #0b1b47;
  text-transform: capitalize;
  padding: 0 0 20px;
}
.BusinessRulesStyles .BusinessRules_container .BusinessRules_whitebox {
  max-width: 60%;
}
@media only screen and (max-width: 1199px) {
  .BusinessRulesStyles .BusinessRules_container .BusinessRules_whitebox {
    max-width: 100%;
  }
}
@media only screen and (max-width: 1023px) {
  .BusinessRulesStyles .BusinessRules_container .BusinessRules_whitebox .formitem_row {
    flex-wrap: wrap;
  }
}
.BusinessRulesStyles .BusinessRules_container .BusinessRules_whitebox .formitem_checkbox_row {
  padding: 0 10px 20px;
}
.BusinessRulesStyles .BusinessRules_container .BusinessRules_whitebox .formitem_checkbox_row .ant-form-item {
  margin: 0;
}

.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CatalogManagementStyles .layout_middel_content {
  padding: 16px 24px 0;
  height: calc(100vh - 134px);
  overflow: hidden;
  overflow-y: auto;
}
.DPLsreeningTableStyles .Table_top_row {
  padding: 0 0 10px;
}
@media only screen and (max-width: 1023px) {
  .DPLsreeningTableStyles .Table_top_row {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media only screen and (max-width: 1023px) {
  .DPLsreeningTableStyles .Table_top_row .Table_action_btns {
    padding: 10px 0 0;
    flex: 0 0 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.DPLsreeningTableStyles .Table_top_row .Table_action_btns .export_link {
  margin-left: 10px;
  border-radius: 4px;
  box-shadow: none;
  font-weight: 600;
  height: 40px;
  padding: 7px 15px;
  font-size: 16px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.6);
}
.DPLsreeningTableStyles .Table_top_row .Table_action_btns .export_link:hover {
  border: 1px solid #3653AA;
  color: #3653AA;
}
.DPLsreeningTableStyles .table_search_block {
  max-width: 380px;
  text-align: left;
  border: none;
  background: none;
  box-shadow: none;
  padding: 0;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .DPLsreeningTableStyles .table_search_block {
    max-width: 100%;
  }
}
.DPLsreeningTableStyles .table_search_block .anticon {
  font-size: 16px;
  color: #53535f;
  margin: 7px 0;
  opacity: 0.8;
}
.DPLsreeningTableStyles .table_search_block .anticon:hover {
  background: none;
}
.DPLsreeningTableStyles .table_search_block input {
  border: none;
  background: none;
  box-shadow: none;
  color: #53535f;
  padding: 5px 4px;
  font-size: 16px;
  line-height: 20px;
}
.DPLsreeningTableStyles .table_search_block .ant-btn {
  margin-left: 10px;
}
@media only screen and (max-width: 767px) {
  .DPLsreeningTableStyles .table_search_block .ant-btn {
    margin-right: 6px;
    margin-top: 0;
  }
}
@media only screen and (min-width: 1440px) {
  .DPLsreeningTableStyles .ant-table-content {
    overflow-x: auto;
    max-width: 100%;
  }
}
.DPLsreeningTableStyles .Table_block_main .ant-table-thead > tr > th .ant-table-filter-column-title {
  white-space: nowrap;
  flex: initial;
  padding-right: 0;
}
.DPLsreeningTableStyles .Table_block_main .ant-table-thead > tr > th .ant-table-filter-trigger-container {
  position: static;
  background: none;
}
.DPLsreeningTableStyles .Table_block_main .ant-table-thead > tr > th .ant-table-filter-trigger-container .ant-table-filter-trigger {
  position: relative;
}
.DPLsreeningTableStyles .Table_block_main .ant-table-tbody > tr > td {
  white-space: normal;
}
.DPLsreeningTableStyles .Table_block_main .ant-table-tbody > tr > td.ellipsis_text {
  white-space: nowrap;
}
.DPLsreeningTableStyles .ant-table-thead > tr > th:last-child,
.DPLsreeningTableStyles .ant-table tfoot > tr > th:last-child {
  background: #ffffff;
  border-bottom: 1px solid #edf2f9;
  right: 0;
  top: auto;
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  box-shadow: none;
}
.DPLsreeningTableStyles .ant-table-tbody > tr > td:last-child,
.DPLsreeningTableStyles .ant-table tfoot > tr > td:last-child {
  background: #ffffff;
  right: 0;
  top: auto;
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  box-shadow: none;
}
.DPLsreeningTableStyles .ant-table-thead tr:hover th:last-child {
  background: #fafafa;
}
.DPLsreeningTableStyles .ant-table-tfoot tr:hover th:last-child {
  background: #fafafa;
}
.DPLsreeningTableStyles .ant-table-tbody tr:hover td:last-child {
  background: #fafafa;
}
.DPLsreeningTableStyles .ant-table-tfoot tr:hover td:last-child {
  background: #fafafa;
}

.AddCatalogStyles .AddCatalog_container .AddCatalog__title {
  font-size: 20px;
  line-height: 22px;
  color: #0b1b47;
  text-transform: capitalize;
  padding: 0 0 20px;
}
.AddCatalogStyles .AddCatalog_container .AddCatalog_whitebox {
  max-width: 60%;
  margin: 0;
}
@media only screen and (max-width: 1023px) {
  .AddCatalogStyles .AddCatalog_container .AddCatalog_whitebox {
    max-width: 100%;
  }
}

.loader1 {
  position: fixed;
  left: 40%;
  top: 35%;
  z-index: 5;
}
.EditProfileStyles .layout_middel_content {
  padding: 16px 24px;
  height: calc(100vh - 134px);
  overflow: hidden;
  overflow-y: auto;
}
.EditProfileStyles .EditProfile_container .EditProfile__title {
  font-size: 20px;
  line-height: 22px;
  color: #0b1b47;
  text-transform: capitalize;
  padding: 0 0 20px;
}
.EditProfileStyles .EditProfile_container .EditProfile_whitebox {
  max-width: 60%;
}
@media only screen and (max-width: 1023px) {
  .EditProfileStyles .EditProfile_container .EditProfile_whitebox {
    max-width: 100%;
  }
}

.loader {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader1 {
  position: fixed;
  left: 40%;
  top: 32%;
  z-index: 5;
}
.ManageDistributionStyles .layout_middel_content {
  padding: 16px 24px;
  height: calc(100vh - 134px);
  overflow: hidden;
  overflow-y: auto;
}
@media only screen and (min-width: 1440px) {
  .ManageDistributionTableStyles .ant-table-content {
    overflow-x: auto;
    max-width: 100%;
  }
}
.ManageDistributionTableStyles .layout-heading {
  font-size: 20px;
  line-height: 22px;
  color: #0b1b47;
  text-transform: capitalize;
  font-weight: 600;
}
@media only screen and (max-width: 1023px) {
  .ManageDistributionTableStyles .layout-heading {
    font-size: 22px;
    line-height: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .ManageDistributionTableStyles .layout-heading {
    font-size: 20px;
    line-height: 22px;
    margin: 12px 0 0;
    white-space: nowrap;
  }
}
.ManageDistributionTableStyles .ant-table-thead > tr > th:last-child,
.ManageDistributionTableStyles .ant-table tfoot > tr > th:last-child {
  background: #ffffff;
  border-bottom: 1px solid #edf2f9;
  right: 0;
  top: auto;
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  text-align: right;
}
.ManageDistributionTableStyles .Table_block_main .ant-table-thead > tr > th .ant-table-filter-column-title {
  white-space: nowrap;
  flex: initial;
  padding-right: 0;
}
.ManageDistributionTableStyles .Table_block_main .ant-table-thead > tr > th .ant-table-filter-trigger-container {
  position: static;
}
.ManageDistributionTableStyles .Table_block_main .ant-table-thead > tr > th .ant-table-filter-trigger-container .ant-table-filter-trigger {
  position: relative;
}
.ManageDistributionTableStyles .Table_block_main .ant-table-tbody > tr > td {
  white-space: normal;
}
.ManageDistributionTableStyles .useremails {
  white-space: normal;
}
.ManageDistributionTableStyles .ant-table-tbody > tr > td:last-child,
.ManageDistributionTableStyles .ant-table tfoot > tr > td:last-child {
  background: #ffffff;
  right: 0;
  top: auto;
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  text-align: right;
}
.ManageDistributionTableStyles .ant-table-thead tr:hover th:last-child {
  background: #ffffff;
}
.ManageDistributionTableStyles .ant-table-tfoot tr:hover th:last-child {
  background: #fafafa;
}
.ManageDistributionTableStyles .ant-table-tbody tr:hover td:last-child {
  background: #fafafa;
}
.ManageDistributionTableStyles .ant-table-tbody .ant-table-cell .ant-select-selector {
  min-width: 70px;
}
.ManageDistributionTableStyles .ant-table-tfoot tr:hover td:last-child {
  background: #fafafa;
}

.DistributionFormStyles .DistributionForm_container .AddCatalog__title {
  font-size: 20px;
  line-height: 22px;
  color: #0b1b47;
  text-transform: capitalize;
  padding: 0 0 20px;
}
.DistributionFormStyles .DistributionForm_container .DistributionForm_whitebox {
  max-width: 60%;
  margin: 0 0 24px;
}
@media only screen and (max-width: 1023px) {
  .DistributionFormStyles .DistributionForm_container .DistributionForm_whitebox {
    max-width: 100%;
  }
}
.DistributionFormStyles .DistributionForm_container .DistributionForm_whitebox .multiple_select .ant-select-selector {
  height: auto;
}

.loader1 {
  position: fixed;
  left: 40%;
  top: 35%;
  z-index: 5;
}
.FTSSettingsStyles .layout_middel_content {
  padding: 16px 24px;
  height: calc(100vh - 134px);
  overflow: hidden;
  overflow-y: auto;
}
.FTSSettingsStyles .FTSSettings_Tabs {
  border: 1px solid #edf2f9;
  background: #ffffff;
  padding: 24px;
  margin: 0 0 24px;
}
.FTSSettingsStyles .FTSSettings_Tabs .ant-tabs-bar {
  margin: 0 0 24px;
}
.FTSSettingsStyles .FTSSettings_Tabs .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
  padding: 0 0 12px;
}
.FTSSettingsStyles .FTSSettings_Tabs .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab:hover {
  color: #3653AA;
}
.FTSSettingsStyles .FTSSettings_Tabs .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab-active {
  color: #3653AA;
  font-weight: 600;
}
.ImportCatalogStyles .ImportCatalog_container .ImportCatalog__title {
  font-size: 20px;
  line-height: 22px;
  color: #0b1b47;
  text-transform: capitalize;
  padding: 0 0 20px;
}
.ImportCatalogStyles .ImportCatalog_container .ImportCatalog_whitebox {
  max-width: 60%;
  margin: 0 0 24px;
}
@media only screen and (max-width: 1023px) {
  .ImportCatalogStyles .ImportCatalog_container .ImportCatalog_whitebox {
    max-width: 100%;
  }
}
@media only screen and (max-width: 1199px) {
  .HistoryTableStyles .ant-table-content {
    overflow-x: auto;
    max-width: 1200px;
  }
}
.HistoryTableStyles .Table_block_main .ant-table-thead > tr > th,
.HistoryTableStyles .Table_block_main .ant-table-tbody > tr > td,
.HistoryTableStyles .Table_block_main .ant-table tfoot > tr > th,
.HistoryTableStyles .Table_block_main .ant-table tfoot > tr > td {
  padding: 16px 8px;
}
.HistoryTableStyles .Table_block_main .ant-table-thead > tr > th,
.HistoryTableStyles .Table_block_main .ant-table tfoot > tr > th {
  padding: 0 8px 12px 8px;
}
.HistoryTableStyles .Table_block_main .ant-table-tbody > tr > td .filename,
.HistoryTableStyles .Table_block_main .ant-table tfoot > tr > td .filename {
  font-weight: 600;
  color: #53535f;
}
.HistoryTableStyles .Table_block_main .ant-table-tbody > tr > td .status.success,
.HistoryTableStyles .Table_block_main .ant-table tfoot > tr > td .status.success {
  color: green;
}
.HistoryTableStyles .Table_block_main .ant-table-tbody > tr > td .status.fail,
.HistoryTableStyles .Table_block_main .ant-table tfoot > tr > td .status.fail {
  color: red;
}
.HistoryTableStyles .Table_block_main .ant-table-thead > tr > th:first-child,
.HistoryTableStyles .Table_block_main .ant-table tfoot > tr > th:first-child {
  text-align: left;
}
.HistoryTableStyles .Table_block_main .ant-table-tbody > tr > td:first-child {
  text-align: left;
}
.HistoryTableStyles .ant-table-tbody > tr.ant-table-row-selected > td {
  background: #fafafa;
}
.ImportSampleOrderStyles .ImportSampleOrder_container .ImportSampleOrder__title {
  font-size: 20px;
  line-height: 22px;
  color: #0b1b47;
  text-transform: capitalize;
  padding: 0 0 20px;
}
.ImportSampleOrderStyles .ImportSampleOrder_container .ImportSampleOrder_whitebox {
  max-width: 60%;
  margin: 0 0 20px;
}
@media only screen and (max-width: 1023px) {
  .ImportSampleOrderStyles .ImportSampleOrder_container .ImportSampleOrder_whitebox {
    max-width: 100%;
  }
}
.ImportSampleOrderStyles .ImportSampleOrder_container .ImportSampleOrder_whitebox .multiple_select .ant-select-selector {
  height: auto;
}

.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ExportSampleOrderStyles .ExportSampleOrder_container .ExportSampleOrder__title {
  font-size: 20px;
  line-height: 22px;
  color: #0b1b47;
  text-transform: capitalize;
  padding: 0 0 20px;
}
.ExportSampleOrderStyles .ExportSampleOrder_container .ExportSampleOrder_whitebox {
  max-width: 60%;
}
@media only screen and (max-width: 1023px) {
  .ExportSampleOrderStyles .ExportSampleOrder_container .ExportSampleOrder_whitebox {
    max-width: 100%;
  }
}
.ImportSampleOrderApprovalStyles .ImportSampleOrderApproval_container .ImportSampleOrderApproval__title {
  font-size: 20px;
  line-height: 22px;
  color: #0b1b47;
  text-transform: capitalize;
  padding: 0 0 20px;
}
.ImportSampleOrderApprovalStyles .ImportSampleOrderApproval_container .ImportSampleOrderApproval_whitebox {
  max-width: 60%;
}
@media only screen and (max-width: 1023px) {
  .ImportSampleOrderApprovalStyles .ImportSampleOrderApproval_container .ImportSampleOrderApproval_whitebox {
    max-width: 100%;
  }
}
.ImportSampleOrderApprovalStyles .noti_list_col_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #edf2f9;
}
.ImportSampleOrderApprovalStyles .noti_list_col_block .noti_info {
  flex-direction: column;
}
.ImportSampleOrderApprovalStyles .noti_list_col_block .noti_info .noti_msg {
  padding: 0 0 10px;
  display: block;
}
.FTSSettingsStyles .layout_middel_content {
  padding: 16px 24px;
  height: calc(100vh - 134px);
  overflow: hidden;
  overflow-y: auto;
}
.FTSSettingsStyles .FTSSettings_Tabs {
  border: 1px solid #edf2f9;
  background: #ffffff;
  padding: 24px;
  margin: 0 0 24px;
}
.FTSSettingsStyles .FTSSettings_Tabs .ant-tabs-bar {
  margin: 0 0 24px;
}
.FTSSettingsStyles .FTSSettings_Tabs .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab {
  padding: 0 0 12px;
}
.FTSSettingsStyles .FTSSettings_Tabs .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab:hover {
  color: #3653AA;
}
.FTSSettingsStyles .FTSSettings_Tabs .ant-tabs-bar .ant-tabs-nav .ant-tabs-tab-active {
  color: #3653AA;
  font-weight: 600;
}
.ImportSampleOrderStyles .ImportSampleOrder_container .ImportSampleOrder__title {
  font-size: 20px;
  line-height: 22px;
  color: #0b1b47;
  text-transform: capitalize;
  padding: 0 0 20px;
}
.ImportSampleOrderStyles .ImportSampleOrder_container .ImportSampleOrder_whitebox {
  max-width: 60%;
  margin: 0 0 20px;
}
@media only screen and (max-width: 1023px) {
  .ImportSampleOrderStyles .ImportSampleOrder_container .ImportSampleOrder_whitebox {
    max-width: 100%;
  }
}
.ImportSampleOrderStyles .ImportSampleOrder_container .ImportSampleOrder_whitebox .multiple_select .ant-select-selector {
  height: auto;
}

.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CustomerChannelStyles .layout_middel_content {
  padding: 16px 24px;
  height: calc(100vh - 111px);
  overflow: hidden;
  overflow-y: auto;
}
.SimpleTableStyles .SimpleTable_container {
  max-width: 100%;
}
@media only screen and (max-width: 1199px) {
  .SimpleTableStyles .SimpleTable_container {
    max-width: 75%;
  }
}
@media only screen and (max-width: 1023px) {
  .SimpleTableStyles .SimpleTable_container {
    max-width: 100%;
  }
}
@media only screen and (min-width: 1440px) {
  .SimpleTableStyles .ant-table-content {
    overflow-x: auto;
    max-width: 100%;
  }
}
.SimpleTableStyles .Table_block_main .ant-table-thead > tr > th .ant-table-filter-column-title {
  white-space: nowrap;
  flex: initial;
  padding-right: 0;
}
.SimpleTableStyles .Table_block_main .ant-table-thead > tr > th .ant-table-filter-trigger-container {
  position: static;
  background: none;
}
.SimpleTableStyles .Table_block_main .ant-table-thead > tr > th .ant-table-filter-trigger-container .ant-table-filter-trigger {
  position: relative;
}
.SimpleTableStyles .Table_block_main .ant-table-tbody > tr > td {
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.SimpleTableStyles .ant-table-thead > tr > th:last-child,
.SimpleTableStyles .ant-table tfoot > tr > th:last-child {
  background: #ffffff;
  border-bottom: 1px solid #edf2f9;
  right: 0;
  top: auto;
  position: static;
  z-index: 3;
  text-align: right;
}
.SimpleTableStyles .ant-table-tbody > tr > td:last-child,
.SimpleTableStyles .ant-table tfoot > tr > td:last-child {
  background: #ffffff;
  right: 0;
  top: auto;
  position: static;
  z-index: 3;
  text-align: right;
}
.SimpleTableStyles .ant-table-thead tr:hover th:last-child {
  background: #ffffff;
}
.SimpleTableStyles .ant-table-tfoot tr:hover th:last-child {
  background: #fafafa;
}
.SimpleTableStyles .ant-table-tbody tr:hover td:last-child {
  background: #fafafa;
}
.SimpleTableStyles .ant-table-tbody .ant-table-cell .ant-select-selector {
  min-width: 70px;
}
.SimpleTableStyles .ant-table-tfoot tr:hover td:last-child {
  background: #fafafa;
}

.AddCatalogStyles .AddCatalog_container .AddCatalog__title {
  font-size: 20px;
  line-height: 22px;
  color: #0b1b47;
  text-transform: capitalize;
  padding: 0 0 20px;
}
.AddCatalogStyles .AddCatalog_container .AddCatalog_whitebox {
  max-width: 60%;
  margin: 0 0 24px;
}
@media only screen and (max-width: 1023px) {
  .AddCatalogStyles .AddCatalog_container .AddCatalog_whitebox {
    max-width: 100%;
  }
}

.loader1 {
  position: fixed;
  left: 40%;
  top: 35%;
  z-index: 5;
}
.UserRolesStyles .layout_middel_content {
  padding: 16px 24px;
  height: calc(100vh - 134px);
  overflow: hidden;
  overflow-y: auto;
}
@media only screen and (max-width: 1199px) {
  .UserRoleTableStyles .ant-table-content {
    overflow-x: auto;
    max-width: 1200px;
  }
}
.UserRoleTableStyles .Table_block_main .ant-table-thead > tr > th,
.UserRoleTableStyles .Table_block_main .ant-table-tbody > tr > td,
.UserRoleTableStyles .Table_block_main .ant-table tfoot > tr > th,
.UserRoleTableStyles .Table_block_main .ant-table tfoot > tr > td {
  padding: 16px 8px;
  text-align: center;
}
.UserRoleTableStyles .Table_block_main .ant-table-tbody > tr > td,
.UserRoleTableStyles .Table_block_main .ant-table tfoot > tr > td {
  text-align: center;
}
.UserRoleTableStyles .Table_block_main .ant-table-tbody > tr > td .username,
.UserRoleTableStyles .Table_block_main .ant-table tfoot > tr > td .username {
  font-weight: 600;
  color: #53535f;
}
.UserRoleTableStyles .Table_block_main .ant-table-thead > tr > th:first-child,
.UserRoleTableStyles .Table_block_main .ant-table tfoot > tr > th:first-child {
  text-align: left;
}
.UserRoleTableStyles .Table_block_main .ant-table-tbody > tr > td:first-child {
  text-align: left;
}
.UserRoleTableStyles .ant-table-tbody > tr.ant-table-row-selected > td {
  background: #fafafa;
}
.CatalogManagementStyles .layout_middel_content {
  padding: 16px 24px 0;
  height: calc(100vh - 134px);
  overflow: hidden;
  overflow-y: auto;
}
.BusinessRulesStyles .layout_middel_content {
  padding: 16px 24px;
  height: calc(100vh - 134px);
  overflow: hidden;
  overflow-y: auto;
}
.BusinessRulesStyles .BusinessRules_container .BusinessRules__title {
  font-size: 20px;
  line-height: 22px;
  color: #0b1b47;
  text-transform: capitalize;
  padding: 0 0 20px;
}
.BusinessRulesStyles .BusinessRules_container .BusinessRules_whitebox {
  max-width: 60%;
}
@media only screen and (max-width: 1199px) {
  .BusinessRulesStyles .BusinessRules_container .BusinessRules_whitebox {
    max-width: 100%;
  }
}
@media only screen and (max-width: 1023px) {
  .BusinessRulesStyles .BusinessRules_container .BusinessRules_whitebox .formitem_row {
    flex-wrap: wrap;
  }
}
.BusinessRulesStyles .BusinessRules_container .BusinessRules_whitebox .formitem_checkbox_row {
  padding: 0 10px 20px;
}
.BusinessRulesStyles .BusinessRules_container .BusinessRules_whitebox .formitem_checkbox_row .ant-form-item {
  margin: 0;
}

.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CatalogManagementStyles .layout_middel_content {
  padding: 16px 24px 0;
  height: calc(100vh - 134px);
  overflow: hidden;
  overflow-y: auto;
}
.CatalogTableEditStyles .Table_top_row {
  padding: 0 0 10px;
}
@media only screen and (max-width: 1023px) {
  .CatalogTableEditStyles .Table_top_row {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media only screen and (max-width: 1023px) {
  .CatalogTableEditStyles .Table_top_row .Table_action_btns {
    padding: 10px 0 0;
    flex: 0 0 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
.CatalogTableEditStyles .Table_top_row .Table_action_btns .export_link {
  margin-left: 10px;
  border-radius: 4px;
  box-shadow: none;
  font-weight: 600;
  height: 40px;
  padding: 7px 15px;
  font-size: 16px;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.6);
}
.CatalogTableEditStyles .Table_top_row .Table_action_btns .export_link:hover {
  border: 1px solid #3653AA;
  color: #3653AA;
}
.CatalogTableEditStyles .table_search_block {
  max-width: 380px;
  text-align: left;
  border: none;
  background: none;
  box-shadow: none;
  padding: 0;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .CatalogTableEditStyles .table_search_block {
    max-width: 100%;
  }
}
.CatalogTableEditStyles .table_search_block .anticon {
  font-size: 16px;
  color: #53535f;
  margin: 7px 0;
  opacity: 0.8;
}
.CatalogTableEditStyles .table_search_block .anticon:hover {
  background: none;
}
.CatalogTableEditStyles .table_search_block input {
  border: none;
  background: none;
  box-shadow: none;
  color: #53535f;
  padding: 5px 4px;
  font-size: 16px;
  line-height: 20px;
}
.CatalogTableEditStyles .table_search_block .ant-btn {
  margin-left: 10px;
}
@media only screen and (max-width: 767px) {
  .CatalogTableEditStyles .table_search_block .ant-btn {
    margin-right: 6px;
    margin-top: 0;
  }
}
@media only screen and (min-width: 1440px) {
  .CatalogTableEditStyles .ant-table-content {
    overflow-x: auto;
    max-width: 100%;
  }
}
.CatalogTableEditStyles .Table_block_main .ant-table-thead > tr > th .ant-table-filter-column-title {
  white-space: nowrap;
  flex: initial;
  padding-right: 0;
}
.CatalogTableEditStyles .Table_block_main .ant-table-thead > tr > th .ant-table-filter-trigger-container {
  position: static;
  background: none;
}
.CatalogTableEditStyles .Table_block_main .ant-table-thead > tr > th .ant-table-filter-trigger-container .ant-table-filter-trigger {
  position: relative;
}
.CatalogTableEditStyles .Table_block_main .ant-table-tbody > tr > td {
  white-space: normal;
}
.CatalogTableEditStyles .ant-table-thead > tr > th:last-child,
.CatalogTableEditStyles .ant-table tfoot > tr > th:last-child {
  background: #ffffff;
  border-bottom: 1px solid #edf2f9;
  right: 0;
  top: auto;
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  box-shadow: none;
}
.CatalogTableEditStyles .ant-table-tbody > tr > td:last-child,
.CatalogTableEditStyles .ant-table tfoot > tr > td:last-child {
  background: #ffffff;
  right: 0;
  top: auto;
  position: -webkit-sticky;
  position: sticky;
  z-index: 3;
  box-shadow: none;
}
.CatalogTableEditStyles .ant-table-thead tr:hover th:last-child {
  background: #fafafa;
}
.CatalogTableEditStyles .ant-table-tfoot tr:hover th:last-child {
  background: #fafafa;
}
.CatalogTableEditStyles .ant-table-tbody tr:hover td:last-child {
  background: #fafafa;
}
.CatalogTableEditStyles .ant-table-tfoot tr:hover td:last-child {
  background: #fafafa;
}

.AddCatalogStyles .AddCatalog_container .AddCatalog__title {
  font-size: 20px;
  line-height: 22px;
  color: #0b1b47;
  text-transform: capitalize;
  padding: 0 0 20px;
}
.AddCatalogStyles .AddCatalog_container .AddCatalog_whitebox {
  max-width: 60%;
  margin: 0;
}
@media only screen and (max-width: 1023px) {
  .AddCatalogStyles .AddCatalog_container .AddCatalog_whitebox {
    max-width: 100%;
  }
}

.loader1 {
  position: fixed;
  left: 40%;
  top: 35%;
  z-index: 5;
}
.CatalogManagementStyles .layout_middel_content {
  padding: 16px 24px 0;
  height: calc(100vh - 134px);
  overflow: hidden;
  overflow-y: auto;
}
.BusinessRulesStyles .layout_middel_content {
  padding: 16px 24px;
  height: calc(100vh - 134px);
  overflow: hidden;
  overflow-y: auto;
}
.BusinessRulesStyles .BusinessRules_container .BusinessRules__title {
  font-size: 20px;
  line-height: 22px;
  color: #0b1b47;
  text-transform: capitalize;
  padding: 0 0 20px;
}
.BusinessRulesStyles .BusinessRules_container .BusinessRules_whitebox {
  max-width: 60%;
}
@media only screen and (max-width: 1199px) {
  .BusinessRulesStyles .BusinessRules_container .BusinessRules_whitebox {
    max-width: 100%;
  }
}
@media only screen and (max-width: 1023px) {
  .BusinessRulesStyles .BusinessRules_container .BusinessRules_whitebox .formitem_row {
    flex-wrap: wrap;
  }
}
.BusinessRulesStyles .BusinessRules_container .BusinessRules_whitebox .formitem_checkbox_row {
  padding: 0 10px 20px;
}
.BusinessRulesStyles .BusinessRules_container .BusinessRules_whitebox .formitem_checkbox_row .ant-form-item {
  margin: 0;
}

.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CatalogManagementStyles .layout_middel_content {
  padding: 16px 24px 0;
  height: calc(100vh - 134px);
  overflow: hidden;
  overflow-y: auto;
}
.BusinessRulesStyles .layout_middel_content {
  padding: 16px 24px;
  height: calc(100vh - 134px);
  overflow: hidden;
  overflow-y: auto;
}
.BusinessRulesStyles .BusinessRules_container .BusinessRules__title {
  font-size: 20px;
  line-height: 22px;
  color: #0b1b47;
  text-transform: capitalize;
  padding: 0 0 20px;
}
.BusinessRulesStyles .BusinessRules_container .BusinessRules_whitebox {
  max-width: 60%;
}
@media only screen and (max-width: 1199px) {
  .BusinessRulesStyles .BusinessRules_container .BusinessRules_whitebox {
    max-width: 100%;
  }
}
@media only screen and (max-width: 1023px) {
  .BusinessRulesStyles .BusinessRules_container .BusinessRules_whitebox .formitem_row {
    flex-wrap: wrap;
  }
}
.BusinessRulesStyles .BusinessRules_container .BusinessRules_whitebox .formitem_checkbox_row {
  padding: 0 10px 20px;
}
.BusinessRulesStyles .BusinessRules_container .BusinessRules_whitebox .formitem_checkbox_row .ant-form-item {
  margin: 0;
}

.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.CatalogManagementStyles .layout_middel_content {
  padding: 16px 24px 0;
  height: calc(100vh - 134px);
  overflow: hidden;
  overflow-y: auto;
}
.ProductFamilyStyles .layout_middel_content {
  padding: 16px 24px;
  height: calc(100vh - 134px);
  overflow: hidden;
  overflow-y: auto;
}
